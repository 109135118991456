/* 修复列表样式 */
.markdown-body ol {
    padding-left: 2em;
  }
  
  .markdown-body ol li {
    display: block;
    position: relative;
  }
  
  .markdown-body ol li:before {
    content: counter(item) ". ";  /* 修改为单层计数器 */
    counter-increment: item;
    position: absolute;
    left: -1.5em;
    font-weight: normal;
  }
  
  /* 在对应的CSS文件中添加 */
.markdown-body ul li {
    position: relative;
    padding-left: 1em;
  }
  
  .markdown-body ul li::before {
    content: "•";
    position: absolute;
    left: 0;
    font-weight: bold;
  }
  
  